import React from 'react'
import PropTypes from 'prop-types'

import ReactTooltip from 'react-tooltip'

import infoIcon from '../../assets/info.svg'
import { Flex } from '../utilities/Flex'

const Tooltip = ({ hint }) => {
  return (
    <Flex>
      <img
        src={infoIcon}
        data-tip={hint}
        data-for="info"
        effect="float"
        alt="Info"
        width="32px"
        height="32px"
        style={{ cursor: 'pointer', height: '32px' }}
      />
      <ReactTooltip
        id="info"
        place="top"
        type="light"
        effect="solid"
        className="tooltip"
        getContent={dataTip => dataTip}
        clickable
      />
    </Flex>
  )
}
Tooltip.defaultProps = {
  hint: '',
}
Tooltip.propTypes = {
  hint: PropTypes.string,
}

export default Tooltip
