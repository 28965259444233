import React from 'react'

import logo from '../../assets/blu_logo.svg'
import mobileLogo from '../../assets/blu_logo_mobile.svg'

const Logo = () => (
  <a href="https://www.blu.com/" target="_blank" rel="noopener noreferrer">
    <img
      src={logo}
      width="56px"
      height="62px"
      alt="Blu logo"
      className="blu_logo"
    />
    <img
      src={mobileLogo}
      width="32px"
      height="28px"
      alt="Blu logo mobile"
      className="blu_logo_mobile"
    />
  </a>
)

export default Logo
