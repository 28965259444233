import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from './styles/GlobalStyles'
import { Theme } from './styles/theme'

import { Wrapper } from './components/utilities/Wrapper'
import { Header } from './components/utilities/Header'
import Logo from './components/utilities/Logo'
import MainLayout from './containers/MainLayout'

const App = () => {
  const [oval, setOval] = useState('no')
  return (
    <ThemeProvider theme={Theme}>
      <Header>
        <Logo />
      </Header>
      <Wrapper oval={oval === 'yes'}>
        <MainLayout onSetOval={value => setOval(value)} />
      </Wrapper>
      <GlobalStyles />
    </ThemeProvider>
  )
}

export default App
