import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
${normalize}
  *,
    *:before,
    *:after {
        box-sizing: inherit;
  }
  #root {
    height: 100%;
  }
  body,
  html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
  }
  h1 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 600;
    @media(max-width: 720px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    @media(max-width: 720px) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  p {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
    @media(max-width: 720px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  a {
    text-decoration: none;
}
  form {
    width: 100%;
    text-align: center;
  }
  .blu_logo {
    display: block;
    @media(max-width: 720px) {
      display: none;
    }
  }
  .blu_logo_mobile {
    display: none;
    @media(max-width: 720px) {
      display: block;
    }
  }
  
  /* ----------------------------------------------
  * Generated by Animista on 2020-2-13 13:38:26
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-200px);
      transform: translateY(-200px);
      opacity: 0;
    }
  }
  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(-25px);
              transform: translateY(-25px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-200px);
      transform: translateY(-200px);
      opacity: 0;
    }
  }

  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(80px);
      transform: translateY(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(80px);
      transform: translateY(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
      opacity: 1;
    }
  }
  .item-enter {
    opacity: 0;
    animation: slide-in-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
    animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }

// Progress Bar Styles
  .progressbar {
    width: 720px;
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media(max-width: 720px) {
        width: 90%;
      }
    }
  }

// Buttons Active & Disabled State
  .active {
    background-color: #fff !important;
    color: #1493d3 !important;
    border: 1px solid #fff !important;
  }
  .disabled {
    opacity: 0.5;
    border: 1px solid #fff;
    &:hover {
      background-color: #fff !important;
      color: #1493d3 !important;
      cursor: not-allowed;
    }
  }

// React Tooltip Styles
  .tooltip {
    font-size: 18px !important;
    color: #157baf !important;
    opacity: 1 !important;
    line-height: 24px !important;
    width: 500px !important;
    text-align: center !important;
    padding: 20px !important;
    border-radius: 25px !important;
    @media(max-width: 720px) {
      width: 90% !important;
    }
   }
   @media(max-width: 720px) {
     .transition_class {
       width: 100%;
       padding: 1rem;
       margin: 1rem;
     }
   }
// Provide feedback button
  @media screen and (max-width: 767px) { 
    .atlwdg-trigger.atlwdg-RIGHT {
      top: auto !important;
      bottom: 134px !important;
    }
  }
  span.nobr {
    white-space: nowrap;
  } 
`

export default GlobalStyle
