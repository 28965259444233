import styled, { css } from 'styled-components'

export const Wrapper = styled.div.attrs(({ oval }) => ({
  oval: oval || false,
}))`
  ${({ theme: { colors }, oval }) => css`
    background-image: linear-gradient(310deg, #2b3dcc, #33aefe);
    transition: background-color 200ms ease-in-out;
    color: ${colors.whiteColor};
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100%;
    position: relative;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
    }
    ${oval &&
      css`
        background: rgb(77, 127, 255);
        background: radial-gradient(
          circle,
          rgba(77, 127, 255, 1) 0%,
          rgba(77, 127, 255, 1) 65%,
          rgba(0, 247, 252, 1) 75%,
          rgba(77, 127, 255, 1) 85%,
          rgba(77, 127, 255, 1) 100%
        );
      `}
  `}
`

export const InnerWrapper = styled.div`
  width: 720px;
  padding: 20px 0;
  @media (max-width: 720px) {
    max-width: 100%;
  }
`
