import styled, { css } from 'styled-components'
import {
  color,
  space,
  typography,
  layout,
  position,
  display,
  flexbox,
} from 'styled-system'

export const Input = styled.input`
  ${({ theme: { colors } }) => css`
    font-size: 24px;
    background: transparent;
    color: ${colors.whiteColor};
    border: none;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 47px;
    padding: 15px;
    outline: none;
    outline-color: ${colors.accentColor};
    margin: 0;
    &::placeholder {
      color: ${colors.whiteColor};
      opacity: 0.6;
    }
    &:focus,
    &:active {
      border-bottom: 1px solid ${colors.whiteColor};
    }
    @media (max-width: 720px) {
      font-size: 18px;
    }
  `}
  ${color}
  ${space}
  ${layout}
  ${position}
  ${typography}
  ${display}
  ${flexbox}
`
