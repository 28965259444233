import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import App from './App'

export const history = createBrowserHistory()
const Root = (
  <Router history={history}>
    <App />
  </Router>
)

ReactDOM.render(Root, document.getElementById('root'))
