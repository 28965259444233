import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Flex } from '../utilities/Flex'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Button from '../button'

const InputValue = styled.input`
  border: 1px solid #fff;
  color: #fff;
  padding: 15px;
  background: transparent;
  border-radius: 5px;
  margin-bottom: 50px;
  width: 160px;
  outline: none;
  text-align: center;
  pointer-events: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`

const InputSlider = ({ slider, slider_default, setValueToFormik }) => {
  const newSlider = slider.map(i => ({
    ...i,
    value: Number(i.display.replace('+', '')),
  }))
  const [value, setValue] = useState(Number(slider_default))
  const min = newSlider[0].value
  const max = newSlider[newSlider.length - 1].value

  useEffect(() => {
    const sliderObj = newSlider.filter(i => i.value === value)[0]
    setValueToFormik(sliderObj.return)
    // Don't Remove it below eslint thing - why ask @WASEEM
    // eslint-disable-next-line
  }, [value])

  const handleChange = e => {
    const { value } = e.target
    if (value >= slider_default) {
      setValue(value)
    } else {
      setValue(1)
    }
  }
  const onMinChange = () => {
    if (value > slider_default && value > 0) {
      setValue(value - 1)
    }
  }
  const onMaxChange = () => {
    if (value < 21) {
      setValue(value + 1)
    }
  }
  const marks = {
    1: {
      style: {
        color: '#fff',
        left: 0,
        top: '20px',
        border: 'none',
      },
      label: <strong>{newSlider[0].display}</strong>,
    },
    21: {
      style: {
        color: '#fff',
        right: '-20px',
        top: '20px',
        border: 'none',
      },
      label: <strong>{newSlider[newSlider.length - 1].display}</strong>,
    },
  }

  return (
    <>
      <InputValue value={value} onChange={handleChange} />
      <Flex alignItems="center" justifyContent="center" mt={4} mb={4}>
        <Button
          hideOnMobile
          type="button"
          width={50}
          minWidth={50}
          height={50}
          mr={4}
          onClick={onMinChange}
        >
          -
        </Button>
        <Flex mb={4} mt={4} ml={3} mr={3}>
          <Slider
            marks={marks}
            handleStyle={{
              height: 50,
              width: 50,
              background:
                '#fff url(https://i.imgur.com/W8WbzBN.png) no-repeat center center',
              backgroundSize: '50% 50%',
              borderRadius: 5,
              border: 'none',
              top: '-13px',
            }}
            railStyle={{ backgroundColor: '#fff', height: 1 }}
            trackStyle={[{ background: '#00A3E9', height: 2 }]}
            min={min}
            max={max}
            value={value}
            onChange={setValue}
            onAfterChange={setValue}
          />
        </Flex>
        <Button
          hideOnMobile
          type="button"
          width={50}
          minWidth={50}
          height={50}
          ml={4}
          onClick={onMaxChange}
        >
          +
        </Button>
      </Flex>
    </>
  )
}

InputSlider.defaultProps = {
  slider: [],
  slider_default: '',
}

InputSlider.propTypes = {
  slider: PropTypes.array,
  slider_default: PropTypes.string,
  setValueToFormik: PropTypes.func.isRequired,
}

export default InputSlider
