import styled from 'styled-components'
import { flexbox, space, position } from 'styled-system'

export const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  ${space}
  ${flexbox}
  ${position}
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`
