import Promise from 'promise'

import * as api from './'

let sessionId = null

export const ApiService = data => {
  sessionId = sessionId || data.sessionId
  return new Promise((resolve, reject) =>
    api
      .post({
        sessionId,
        text: data.text,
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error.data)),
  )
}
