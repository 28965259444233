import React from 'react'
import styled, { keyframes } from 'styled-components'

const dotanim = keyframes`
0% {
  opacity: 0.4;
  transform: scale(0.6, 0.6);
}

50% {
  opacity: 1;
  transform: scale(0.8, 0.8);
}

100% {
  opacity: 0.4;
  transform: scale(0.6, 0.6);
}
`

const Dot = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  animation-fill-mode: both;
  animation-name: ${dotanim};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin: 0px 2px;
  animation-delay: ${props => props.d};
`

const Loader = () => (
  <React.Fragment>
    <Dot />
    <Dot d=".2s" />
    <Dot d=".4s" />
  </React.Fragment>
)

export default Loader
