import axios from 'axios'

import { SERVER } from '../settings'

axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      // alert('We are facing Network issues.Please come back again');
      return null
    }
    if ([502].includes(error.response.status)) {
      return Promise.reject(error)
    }
    if (error.response.status === 401) {
      // window.location.reload(false);
      return Promise.reject(error)
    }
    if ([400, 403].includes(error.response.status)) {
      return Promise.reject(error)
    }
    return error
  },
)

const authHeaders = () => ({
  Authorization: null, // eslint-disable-line
})

export const get = () =>
  axios({
    url: SERVER,
    method: 'get',
    headers: authHeaders(),
  })
    .then(response => response)
    .catch(error => Promise.reject(error.response))

export const post = data =>
  axios({
    url: SERVER,
    method: 'post',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => response)
    .catch(error => Promise.reject(error.response))

export const patch = data =>
  axios({
    url: SERVER,
    method: 'patch',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => response)
    .catch(error => Promise.reject(error.response))

export const remove = data =>
  axios({
    url: SERVER,
    method: 'delete',
    headers: authHeaders(),
    responseType: 'json',
    data,
  })
    .then(response => response)
    .catch(error => Promise.reject(error.response))
