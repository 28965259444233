import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background: #fff;
  @media (max-width: 720px) {
    height: 50px;
  }
`
