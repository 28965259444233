import styled, { css } from 'styled-components'
import {
  color,
  space,
  typography,
  layout,
  position,
  display,
  flexbox,
} from 'styled-system'

const Button = styled.button.attrs(
  ({ hideOnMobile, fullWidth, nextButton }) => ({
    hideOnMobile: hideOnMobile || false,
    fullWidth: fullWidth || false,
    nextButton: nextButton || false,
  }),
)`
  ${({
    theme: { colors, globalRadius, globalTransition },
    hideOnMobile,
    fullWidth,
    nextButton,
  }) => css`
  font-size: 18px;
  text-align: center;
  background-color: transparent;
  border-radius: ${globalRadius}
  color: ${colors.whiteColor};
  border: 1px solid ${colors.whiteColor};
  width: 205px;
  height: 55px;
  padding: 15px;
  font-weight: 500;
  outline: none;
  transition: ${globalTransition};
  cursor: pointer;
  &:hover {
    background-color: rgba(255,255,255,0.15);
    color: ${colors.whiteColor};
    border: 1px solid ${colors.whiteColor};
    @media (max-width: 720px) {
      background-color: transparent;
      color: ${colors.whiteColor};
      border: 1px solid ${colors.whiteColor};
    }
  }
  &:disabled {
    opacity: 0.5;
    border: 1px solid ${colors.whiteColor};
    &:hover {
      background: transparent;
      color: ${colors.whiteColor};
      cursor: not-allowed;
    }
  }
  ${nextButton &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      background: ${colors.whiteColor};
      color: ${colors.accentColor};
      margin-top: 30px !important;
      &:hover {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid ${colors.whiteColor};
        color: ${colors.accentColor};
      }
    `}
  ${hideOnMobile &&
    css`
      @media (max-width: 850px) {
        display: none;
      }
    `}
    ${fullWidth &&
      css`
        @media (max-width: 600px) {
          width: 100%;
        }
      `}
 `}

  ${color}
  ${space}
  ${layout}
  ${position}
  ${typography}
  ${display}
  ${flexbox}
  @media (max-width: 720px) {
    width: 100%;
    margin: 10px;
    font-size: 18px;
  }
`

export default Button
