import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { InnerWrapper } from '../components/utilities/Wrapper'
import { ApiService } from '../services/chat'
import ProgressBar from '../components/progress-bar'

import Forms from '../components/Forms'

class MainLayout extends React.Component {
  state = {
    text: 'welcome',
    responseJson: {},
    progress: null,
    maxprogress: null,
  }
  componentDidMount() {
    this.callingAPIService(Math.floor(1000 + Math.random() * 9000).toString())
  }

  callingAPIService = (sessionId = undefined, cb = null) => {
    const { text } = this.state
    const { onSetOval } = this.props
    ApiService({
      sessionId,
      text,
    }).then(data => {
      if (data.type === 'url') {
        return window.open(data.url, '_self')
      }
      onSetOval(data.show_halo)
      this.setState({ responseJson: {} })
      setTimeout(() => {
        this.setState(
          {
            responseJson: data,
            progress: data.progress || null,
            maxprogress: data.maximum_progress || null,
          },
          cb,
        )
      }, 500)
      window.dataLayer.push(data.ga)
    })
  }

  handleSubmit = (values, type = null, cb) => {
    this.setState(
      {
        text:
          type === 'pills'
            ? values.buttons.length > 1
              ? values.buttons.join(' or ')
              : values.buttons[0]
            : values.text,
      },
      () => this.callingAPIService(undefined, cb),
    )
  }
  render() {
    const { responseJson, progress, maxprogress } = this.state
    const { handleSubmit } = this
    return (
      <>
        <TransitionGroup className="transition_class">
          <CSSTransition
            key={responseJson.progress}
            timeout={500}
            classNames="item"
          >
            <InnerWrapper>
              {responseJson.progress ? (
                <Forms
                  key={responseJson.timer !== null}
                  submitFunction={handleSubmit}
                  pills={responseJson.pills}
                  slider={responseJson.slider}
                  slider_default={responseJson.slider_default}
                  next_response={responseJson.next_response}
                  text={responseJson.text}
                  type={responseJson.type}
                  timer={responseJson.timer}
                  url={responseJson.url}
                  hint={responseJson.hint}
                  pillChoose={responseJson.pill_choose}
                  buttonName={responseJson.continue}
                />
              ) : null}
            </InnerWrapper>
          </CSSTransition>
        </TransitionGroup>
        {progress ? (
          <ProgressBar maximumProgress={maxprogress} progress={progress} />
        ) : null}
      </>
    )
  }
}

MainLayout.propTypes = {
  onSetOval: PropTypes.func.isRequired,
}

export default MainLayout
