export const Theme = {
  // Colors
  colors: {
    accentColor: '#00A3E9',
    blueColor: '#1493d3',
    primaryColor: '#6F82FF',
    whiteColor: '#ffffff',
  },

  // Transition
  globalTransition: '.3s all ease',

  // Borders
  globalRadius: '8px',
}
