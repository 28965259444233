import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormik } from 'formik'
import CommonMark from 'commonmark'
import ReactRenderer from 'commonmark-react-renderer'

import Button from '../button'
import InputSlider from '../input-slider'
import Tooltip from '../tooltip'
import { Input } from '../input'
import { Flex } from '../utilities/Flex'
import { H1 } from '../utilities/Headings'
import arrowDown from '../../assets/arrow-down.svg'
import Loader from '../utilities/Loader'

const messageFormat = data => {
  const parser = new CommonMark.Parser()
  const renderer = new ReactRenderer()
  return renderer.render(parser.parse(data))
}
const Forms = ({
  text,
  next_response,
  pills,
  slider,
  slider_default,
  hint,
  submitFunction,
  type,
  timer,
  url,
  pillChoose,
  buttonName,
}) => {
  const [timerSwitch, setTimer] = useState(timer !== null)

  const formik = useFormik({
    initialValues: {
      text: '',
      buttons: [],
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      submitFunction(values, type, () => {
        setSubmitting(false)
        resetForm({
          text: '',
          buttons: [],
        })
      })
    },
  })

  useEffect(() => {
    setTimer(timer !== null)
    if (timer !== null) {
      setTimeout(() => {
        formik.setValues({
          ...formik.values,
          text: next_response,
        })
        formik.submitForm()
      }, Number(timer) * 1000)
    }
    // don't remove it because next_response, formik doesn't need to get triggered everytime they changed
    // eslint-disable-line
  }, [timer, url])

  const ButtonsHandling = (e, pill) => {
    e.preventDefault()
    let buttonslist = formik.values.buttons
    if (pillChoose !== null) {
      if (formik.values.buttons.filter(i => i === pill.return).length > 0) {
        buttonslist.splice(buttonslist.indexOf(pill.return), 1)
        formik.setValues({
          ...formik.values,
          buttons: buttonslist,
        })
      } else {
        if (buttonslist.length < Number(pillChoose)) {
          formik.setValues({
            ...formik.values,
            buttons: [...buttonslist, pill.return],
          })
        }
      }
    } else {
      formik.setValues({
        ...formik.values,
        buttons: [pill.return],
      })
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        {timerSwitch && type === 'next_response' ? <Loader /> : null}
        {text ? (
          <H1 mb="4">
            {messageFormat(text)} {hint ? <Tooltip hint={hint} /> : null}
          </H1>
        ) : null}

        {/* Input Field with Submit Button */}
        {type === 'input' ? (
          <>
            <Flex mt="4" mb="4">
              <Input
                type="text"
                name="text"
                placeholder="Type here..."
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
              />
            </Flex>
            <Flex>
              <Button
                fullWidth
                nextButton
                m="3"
                type="submit"
                className={formik.values.text ? null : 'disabled'}
              >
                {buttonName}
                <img
                  src={arrowDown}
                  alt="Next question"
                  width="24px"
                  style={{ marginLeft: '10px' }}
                />
              </Button>
            </Flex>
          </>
        ) : null}
        {/* Pills */}

        {pills && pills.length > 1 ? (
          <React.Fragment>
            <Flex alignItems="center" flexWrap="wrap">
              {pills.map(pill => (
                <Button
                  key={pill.return}
                  m="2"
                  width="45%"
                  type="button"
                  onClick={e => ButtonsHandling(e, pill)}
                  className={
                    formik.values.buttons.includes(pill.return)
                      ? 'active'
                      : null
                  }
                >
                  {pill.display}
                </Button>
              ))}
            </Flex>
            <Flex>
              <Button
                fullWidth
                nextButton
                m="3"
                type="submit"
                className={
                  formik.values.buttons.length > 0
                    ? pillChoose
                      ? formik.values.buttons.length === Number(pillChoose)
                        ? null
                        : 'disabled'
                      : null
                    : 'disabled'
                }
                disabled={
                  formik.values.buttons.length > 0
                    ? pillChoose
                      ? formik.values.buttons.length === Number(pillChoose)
                        ? null
                        : 'disabled'
                      : null
                    : 'disabled'
                }
              >
                {buttonName}
                <img
                  src={arrowDown}
                  alt="Next question"
                  width="24px"
                  style={{ marginLeft: '10px' }}
                />
              </Button>
            </Flex>
          </React.Fragment>
        ) : null}

        {/* Slider with Submit Button */}
        {type === 'slider' ? (
          <>
            <InputSlider
              slider={slider}
              slider_default={slider_default}
              setValueToFormik={data =>
                formik.setValues({
                  ...formik.values,
                  text: data,
                })
              }
            />
            <Flex>
              <Button fullWidth nextButton m="3" type="submit">
                {buttonName}
                <img
                  src={arrowDown}
                  alt="Next question"
                  width="24px"
                  style={{ marginLeft: '10px' }}
                />
              </Button>
            </Flex>
          </>
        ) : null}
        {/* Button Field with next_response */}
        {timer === null && (type === 'next_response' || type === 'url_next') ? (
          <Flex>
            <Button
              fullWidth
              nextButton
              m="3"
              type="button"
              onClick={e => {
                e.preventDefault()
                if (type === 'url_next') {
                  return window.open(url, '_self')
                }
                formik.setValues({
                  ...formik.values,
                  text: next_response,
                })
                formik.submitForm()
              }}
            >
              {buttonName}
              <img
                src={arrowDown}
                alt="Next question"
                width="24px"
                style={{ marginLeft: '10px' }}
              />
            </Button>
          </Flex>
        ) : null}
      </div>
    </form>
  )
}

Forms.defaultProps = {
  next_response: null,
  text: null,
  pills: [],
  slider: [],
  timer: null,
  url: null,
  type: null,
  hint: null,
  slider_default: '',
  pillChoose: null,
}

Forms.propTypes = {
  pills: PropTypes.array,
  slider: PropTypes.array,
  next_response: PropTypes.string,
  text: PropTypes.string,
  submitFunction: PropTypes.func.isRequired,
  type: PropTypes.string,
  timer: PropTypes.string,
  url: PropTypes.string,
  hint: PropTypes.string,
  slider_default: PropTypes.string,
  pillChoose: PropTypes.string,
  buttonName: PropTypes.string.isRequired,
}

export default Forms
