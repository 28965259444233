import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ProgressLine = styled.div`
  position: absolute;
  width: 100%;
  transition: width 500ms;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
`

const LineElement = styled.div.attrs(({ white }) => ({
  white: white || false,
}))`
  ${({ white }) => css`
    z-index: 2;
    flex: 1 0 auto;
    width: calc(100% / ${props => props.progress});
    margin: 0;
    height: 6px;
    background-color: #fff;
    transition: transform 500ms;
    ${white &&
      css`
        z-index: 0;
        height: 6px;
        background-color: #fff;
        opacity: 0.2;
      `}
  `}
`

const ProgressBar = ({ maximumProgress, progress }) => {
  const items = [...Array(Number(maximumProgress)).keys()]

  const [...lines] = items

  return (
    <div className="progressbar">
      <ProgressLine>
        {lines.map(item => (
          <LineElement key={item} white={!(Number(progress) - 1 >= item)} />
        ))}
      </ProgressLine>
    </div>
  )
}

ProgressBar.propTypes = {
  maximumProgress: PropTypes.string.isRequired,
  progress: PropTypes.string.isRequired,
}
export default ProgressBar
